import registry from '../../registry';
import { BreadcrumbRouteObject } from '../../ui/breadcrumbs/breadcrumbs';
import { HelmetRouteObject } from '../../ui/helmet/helmet-outlet';
import Translate from '../../util/translate/translate';
import { FILM_VIEWER_TAB } from './film-page/film-viewer-tab';

export const PARAM_FILM_ID = 'filmId';

const filmRoutes: (BreadcrumbRouteObject & HelmetRouteObject)[] = [
  {
    path: 'films',
    handle: {
      breadcrumb: <Translate ns="film" i18nKey="lister.title" />,
    },
    children: [
      {
        path: '',
        lazy: () => import('./film-lister/film-lister-route'),
      },
      {
        path: `:${PARAM_FILM_ID}`,
        lazy: () => import('./film-page/film-page-route'),
        children: [
          {
            path: '',
            lazy: () => import('./film-viewer/film-viewer-route'),
          },
          {
            path: 'history',
            lazy: () => import('./film-history/film-history-route'),
          },
          ...registry.getAll(FILM_VIEWER_TAB).map((value) => value.route),
        ],
      },
    ],
  },
];

export default filmRoutes;
