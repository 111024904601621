import React from 'react';
import { useTranslation } from 'react-i18next';
import { groupIcon } from '../../../ui/icons/business-objects';
import { PageTab } from '../../../ui/page';
import { PluginRegistry } from '../../../util/plugin/use-plugins';
import Translate from '../../../util/translate/translate';
import { FILM_HISTORY_EXTENSION } from '../../film/film-history/film-history-extension';
import { FILM_VIEWER_TAB } from '../../film/film-page/film-viewer-tab';
import { filmHistoryGroupExtension } from '../../group/group-film-relation/film-history-group-extension';
import { GROUP_CAPACITY_VALIDATION_EXTENSION } from '../../group/group-form/group-capacity-validation-extension';
import { useGroupPermissionClassGroupExtension } from '../../group/group-permissions';
import {
  GROUP_COMPANY_RELATION_EXTENSION,
  GROUP_PERSON_RELATION_EXTENSION,
  GROUP_STAFF_RELATION_EXTENSION,
} from '../../group/group-relation/group-relation-extension';
import {
  GROUP_COMPANY_RELATION_HISTORY_EXTENSION,
  GROUP_PERSON_RELATION_HISTORY_EXTENSION,
  GROUP_STAFF_RELATION_HISTORY_EXTENSION,
} from '../../group/group-relation/group-relation-history-extension';
import GroupSearchResult from '../../group/group-search-result';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../../permission-class-group/permission-class-group-extension';
import { MERGE_CONFLICT_PROVIDER_EXTENSION } from '../../person/person-merger/merge-conflict-provider-extension';
import { SEARCH_FILTER } from '../../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../../search/search-plugin';
import { groupCompanyHistoryExtension } from './group-company/group-company-history/group-company-history';
import { groupCompanyPageContentExtension } from './group-company/group-company-page-content';
import { validateGroupCapacity } from './group-person/group-capacity-validation/group-capacity-validation';
import { groupPersonHistoryExtension } from './group-person/group-person-history/group-person-history';
import { groupPersonPageContentExtension } from './group-person/group-person-page-content';
import { getMergeConflictMessages } from './group-person/person-extension/group-person-merge-conflict-provider';
import { groupStaffHistoryExtension } from './group-staff/group-staff-history/group-staff-history';
import { groupStaffPageContentExtension } from './group-staff/group-staff-page-content';

export default function GroupPluginProvider(registry: PluginRegistry) {
  registry.register(GROUP_PERSON_RELATION_EXTENSION, groupPersonPageContentExtension);
  registry.register(GROUP_COMPANY_RELATION_EXTENSION, groupCompanyPageContentExtension);
  registry.register(GROUP_STAFF_RELATION_EXTENSION, groupStaffPageContentExtension);
  registry.register(GROUP_PERSON_RELATION_HISTORY_EXTENSION, groupPersonHistoryExtension);
  registry.register(GROUP_COMPANY_RELATION_HISTORY_EXTENSION, groupCompanyHistoryExtension);
  registry.register(GROUP_STAFF_RELATION_HISTORY_EXTENSION, groupStaffHistoryExtension);
  registry.register(GROUP_CAPACITY_VALIDATION_EXTENSION, validateGroupCapacity);
  registry.register(MERGE_CONFLICT_PROVIDER_EXTENSION, getMergeConflictMessages);
  registry.register(SEARCH_RESULT_CONTENT, { type: 'GROUP', Component: GroupSearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'GROUP',
    icon: groupIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.GROUP" />,
    necessaryPermission: 'GROUP:CAN_SEE_DETAILS',
  });
  registry.register(FILM_VIEWER_TAB, {
    route: {
      path: 'groups',
      lazy: () => import('../../group/group-film-relation/film-group-route'),
    },
    tab: <FilmGroupsTab key="FilmGroupsTab" />,
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useGroupPermissionClassGroupExtension);
  registry.register(FILM_HISTORY_EXTENSION, filmHistoryGroupExtension);
}

function FilmGroupsTab() {
  const { t } = useTranslation(['group']);
  return (
    <PageTab to="./groups" icon={groupIcon}>
      {t('group:filmRelation.groups')}
    </PageTab>
  );
}
