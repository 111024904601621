/* tslint:disable */
/* eslint-disable */
/**
 * BerlinalX API
 * The REST API of the BerlinalX backend server.
 *
 * The version of the OpenAPI document: 0.1
 * Contact: berlinalx@wps.de
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


import * as runtime from '../runtime';
import type {
  FailureDto,
  HistoryPageDto,
  LandingPageDto,
  LandingPagePageDto,
  LandingPageReferencePageDto,
  PageableDto,
} from '../models/index';
import {
    FailureDtoFromJSON,
    FailureDtoToJSON,
    HistoryPageDtoFromJSON,
    HistoryPageDtoToJSON,
    LandingPageDtoFromJSON,
    LandingPageDtoToJSON,
    LandingPagePageDtoFromJSON,
    LandingPagePageDtoToJSON,
    LandingPageReferencePageDtoFromJSON,
    LandingPageReferencePageDtoToJSON,
    PageableDtoFromJSON,
    PageableDtoToJSON,
} from '../models/index';

export interface CreateLandingPageRequest {
    landingPageDto: LandingPageDto;
}

export interface DeleteLandingPageRequest {
    id: string;
}

export interface FindLandingPageByNameRequest {
    name: string;
}

export interface GetLandingPageRequest {
    id: string;
}

export interface GetLandingPageDefaultValuesRequest {
    templateId: string;
}

export interface GetLandingPageDefaultValuesForCopyRequest {
    id: string;
}

export interface GetLandingPageHistoryRequest {
    id: string;
    pageable?: PageableDto;
}

export interface IsLandingPageDeletableRequest {
    id: string;
}

export interface ListLandingPagesRequest {
    templateId?: string;
    filter?: Array<string>;
    pageable?: PageableDto;
}

export interface SearchLandingPageReferencesRequest {
    pageable?: PageableDto;
    types?: Array<string>;
    searchquery?: string;
}

export interface UpdateLandingPageRequest {
    id: string;
    landingPageDto: LandingPageDto;
}

/**
 * 
 */
export class LandingPageApi extends runtime.BaseAPI {

    /**
     * Create a new landing page
     */
    async createLandingPageRaw(requestParameters: CreateLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['landingPageDto'] == null) {
            throw new runtime.RequiredError(
                'landingPageDto',
                'Required parameter "landingPageDto" was null or undefined when calling createLandingPage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages`,
            method: 'POST',
            headers: headerParameters,
            query: queryParameters,
            body: LandingPageDtoToJSON(requestParameters['landingPageDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Create a new landing page
     */
    async createLandingPage(requestParameters: CreateLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.createLandingPageRaw(requestParameters, initOverrides);
    }

    /**
     * Delete landing page
     */
    async deleteLandingPageRaw(requestParameters: DeleteLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling deleteLandingPage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'DELETE',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Delete landing page
     */
    async deleteLandingPage(requestParameters: DeleteLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.deleteLandingPageRaw(requestParameters, initOverrides);
    }

    /**
     * Find the landing page by name
     */
    async findLandingPageByNameRaw(requestParameters: FindLandingPageByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDto>> {
        if (requestParameters['name'] == null) {
            throw new runtime.RequiredError(
                'name',
                'Required parameter "name" was null or undefined when calling findLandingPageByName().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['name'] != null) {
            queryParameters['name'] = requestParameters['name'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/find-by-name`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDtoFromJSON(jsonValue));
    }

    /**
     * Find the landing page by name
     */
    async findLandingPageByName(requestParameters: FindLandingPageByNameRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDto | null | undefined > {
        const response = await this.findLandingPageByNameRaw(requestParameters, initOverrides);
        switch (response.raw.status) {
            case 200:
                return await response.value();
            case 204:
                return null;
            default:
                return await response.value();
        }
    }

    /**
     * Get a single landing page
     */
    async getLandingPageRaw(requestParameters: GetLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getLandingPage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDtoFromJSON(jsonValue));
    }

    /**
     * Get a single landing page
     */
    async getLandingPage(requestParameters: GetLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDto> {
        const response = await this.getLandingPageRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the default values for a new landing page for a template
     */
    async getLandingPageDefaultValuesRaw(requestParameters: GetLandingPageDefaultValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDto>> {
        if (requestParameters['templateId'] == null) {
            throw new runtime.RequiredError(
                'templateId',
                'Required parameter "templateId" was null or undefined when calling getLandingPageDefaultValues().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/default-values`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the default values for a new landing page for a template
     */
    async getLandingPageDefaultValues(requestParameters: GetLandingPageDefaultValuesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDto> {
        const response = await this.getLandingPageDefaultValuesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Get the default values for a copy of an existing landing page
     */
    async getLandingPageDefaultValuesForCopyRaw(requestParameters: GetLandingPageDefaultValuesForCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getLandingPageDefaultValuesForCopy().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/{id}/default-values`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageDtoFromJSON(jsonValue));
    }

    /**
     * Get the default values for a copy of an existing landing page
     */
    async getLandingPageDefaultValuesForCopy(requestParameters: GetLandingPageDefaultValuesForCopyRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageDto> {
        const response = await this.getLandingPageDefaultValuesForCopyRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     */
    async getLandingPageHistoryRaw(requestParameters: GetLandingPageHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<HistoryPageDto>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling getLandingPageHistory().'
            );
        }

        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/{id}/versions`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => HistoryPageDtoFromJSON(jsonValue));
    }

    /**
     */
    async getLandingPageHistory(requestParameters: GetLandingPageHistoryRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<HistoryPageDto> {
        const response = await this.getLandingPageHistoryRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Check whether the entity can be deleted
     */
    async isLandingPageDeletableRaw(requestParameters: IsLandingPageDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<boolean>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling isLandingPageDeletable().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/{id}/deletable`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        if (this.isJsonMime(response.headers.get('content-type'))) {
            return new runtime.JSONApiResponse<boolean>(response);
        } else {
            return new runtime.TextApiResponse(response) as any;
        }
    }

    /**
     * Check whether the entity can be deleted
     */
    async isLandingPageDeletable(requestParameters: IsLandingPageDeletableRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<boolean> {
        const response = await this.isLandingPageDeletableRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * List existing Landing-Pages
     */
    async listLandingPagesRaw(requestParameters: ListLandingPagesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPagePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['templateId'] != null) {
            queryParameters['templateId'] = requestParameters['templateId'];
        }

        if (requestParameters['filter'] != null) {
            queryParameters['filter'] = requestParameters['filter'];
        }

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPagePageDtoFromJSON(jsonValue));
    }

    /**
     * List existing Landing-Pages
     */
    async listLandingPages(requestParameters: ListLandingPagesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPagePageDto> {
        const response = await this.listLandingPagesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Search for landingPage references
     */
    async searchLandingPageReferencesRaw(requestParameters: SearchLandingPageReferencesRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<LandingPageReferencePageDto>> {
        const queryParameters: any = {};

        if (requestParameters['pageable'] != null) {
            queryParameters['pageable'] = requestParameters['pageable'];
        }

        if (requestParameters['types'] != null) {
            queryParameters['types'] = requestParameters['types'];
        }

        if (requestParameters['searchquery'] != null) {
            queryParameters['searchquery'] = requestParameters['searchquery'];
        }

        const headerParameters: runtime.HTTPHeaders = {};

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-page-references`,
            method: 'GET',
            headers: headerParameters,
            query: queryParameters,
        }, initOverrides);

        return new runtime.JSONApiResponse(response, (jsonValue) => LandingPageReferencePageDtoFromJSON(jsonValue));
    }

    /**
     * Search for landingPage references
     */
    async searchLandingPageReferences(requestParameters: SearchLandingPageReferencesRequest = {}, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<LandingPageReferencePageDto> {
        const response = await this.searchLandingPageReferencesRaw(requestParameters, initOverrides);
        return await response.value();
    }

    /**
     * Update the existing landing page.
     */
    async updateLandingPageRaw(requestParameters: UpdateLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<runtime.ApiResponse<void>> {
        if (requestParameters['id'] == null) {
            throw new runtime.RequiredError(
                'id',
                'Required parameter "id" was null or undefined when calling updateLandingPage().'
            );
        }

        if (requestParameters['landingPageDto'] == null) {
            throw new runtime.RequiredError(
                'landingPageDto',
                'Required parameter "landingPageDto" was null or undefined when calling updateLandingPage().'
            );
        }

        const queryParameters: any = {};

        const headerParameters: runtime.HTTPHeaders = {};

        headerParameters['Content-Type'] = 'application/json';

        if (this.configuration && this.configuration.accessToken) {
            const token = this.configuration.accessToken;
            const tokenString = await token("BearerAuth", []);

            if (tokenString) {
                headerParameters["Authorization"] = `Bearer ${tokenString}`;
            }
        }
        const response = await this.request({
            path: `/landing-pages/{id}`.replace(`{${"id"}}`, encodeURIComponent(String(requestParameters['id']))),
            method: 'PUT',
            headers: headerParameters,
            query: queryParameters,
            body: LandingPageDtoToJSON(requestParameters['landingPageDto']),
        }, initOverrides);

        return new runtime.VoidApiResponse(response);
    }

    /**
     * Update the existing landing page.
     */
    async updateLandingPage(requestParameters: UpdateLandingPageRequest, initOverrides?: RequestInit | runtime.InitOverrideFunction): Promise<void> {
        await this.updateLandingPageRaw(requestParameters, initOverrides);
    }

}
