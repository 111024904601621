import React from 'react';
import { useTranslation } from 'react-i18next';
import { companyIcon } from '../../ui/icons/business-objects';
import { PageTab } from '../../ui/page';
import { PluginRegistry } from '../../util/plugin/use-plugins';
import Translate from '../../util/translate/translate';
import { FILM_HISTORY_EXTENSION } from '../film/film-history/film-history-extension';
import { FILM_VIEWER_TAB } from '../film/film-page/film-viewer-tab';
import { PERMISSION_CLASS_GROUP_EXTENSION } from '../permission-class-group/permission-class-group-extension';
import { SEARCH_FILTER } from '../search/search-filter-plugin';
import { SEARCH_RESULT_CONTENT } from '../search/search-plugin';
import { companyFilmHistoryExtension } from './company-film/history/company-film-history';
import { filmCompanyHistoryExtension } from './company-film/history/film-company-history-extension';
import { useCompanyPermissionClassGroupExtension } from './company-permissions';
import { COMPANY_HISTORY_EXTENSION } from './company-relation/company-history-extension';
import CompanySearchResult from './company-search-result';

export default function CompanyPluginProvider(registry: PluginRegistry) {
  registry.register(SEARCH_RESULT_CONTENT, { type: 'COMPANY', Component: CompanySearchResult });
  registry.register(SEARCH_FILTER, {
    key: 'COMPANY',
    icon: companyIcon,
    label: <Translate ns="app" i18nKey="search.resultTypeLabels.COMPANY" />,
    necessaryPermission: 'COMPANY:CAN_SEE_DETAILS',
  });

  registry.register(PERMISSION_CLASS_GROUP_EXTENSION, useCompanyPermissionClassGroupExtension);
  registry.register(FILM_VIEWER_TAB, {
    route: {
      path: 'companies',
      lazy: () => import('./company-film/film-companies-route'),
    },
    tab: <FilmCompaniesTab key="FilmCompaniesTab" />,
  });
  registry.register(COMPANY_HISTORY_EXTENSION, companyFilmHistoryExtension);
  registry.register(FILM_HISTORY_EXTENSION, filmCompanyHistoryExtension);
}

function FilmCompaniesTab() {
  const { t } = useTranslation(['company']);
  return (
    <PageTab to="./companies" icon={companyIcon}>
      {t('company:relation.film.companies')}
    </PageTab>
  );
}
