import AccessGroupPluginProvider from './feature/access-group/access-group-plugin-provider';
import AccountPluginProvider from './feature/account/account-plugin-provider';
import AdmissionProvider from './feature/admission/admission-provider';
import LocationAppointmentProvider from './feature/appointment/location/location-appointment-provider';
import PersonAppointmentProvider from './feature/appointment/person/person-appointment-provider';
import AwardPluginProvider from './feature/award/award-plugin-provider';
import CodePluginProvider from './feature/code/code-plugin-provider';
import CompanyAdminPluginProvider from './feature/company-admin/company-admin-plugin-provider';
import CompanyPluginProvider from './feature/company/company-plugin-provider';
import CountryPluginProvider from './feature/country/country-plugin-provider';
import EditionPluginProvider from './feature/edition/edition-plugin-provider';
import EventEvaluationProvider from './feature/event-evaluation/event-evaluation-provider';
import EventPluginProvider from './feature/event/event-plugin-provider';
import EventProvider from './feature/event/event-provider';
import EventsOfPersonProvider from './feature/events-of-person/events-of-person-provider';
import ExportPluginProvider from './feature/export/export-plugin-provider';
import FilmPluginProvider from './feature/film/film-plugin-provider';
import FormatPluginProvider from './feature/format/format-plugin-provider';
import GroupTemplatePluginProvider from './feature/group-template/group-template-plugin-provider';
import GuestListProvider from './feature/guest-list/guest-list-provider';
import GuestListLandingPageProvider from './feature/guest-list/landing-page/guest-list-landing-page-provider';
import InvoicePluginProvider from './feature/invoice/invoice-plugin-provider';
import KeyPlayerPluginProvider from './feature/key-players/key-player-plugin-provider';
import LandingPageDesignPluginProvider from './feature/landing-page-design/landing-page-design-plugin-provider';
import LandingPageTemplateModuleProvider from './feature/landing-page-template/landing-page-template-modules/landing-page-template-module-plugin-provider';
import LandingPageTemplatePluginProvider from './feature/landing-page-template/landing-page-template-plugin-provider';
import LandingPageModuleProvider from './feature/landing-page/landing-page-modules/landing-page-module-plugin-provider';
import LandingPagePluginProvider from './feature/landing-page/landing-page-plugin-provider';
import ListPluginProvider from './feature/list/list-plugin-provider';
import LiveTicketingPluginProvider from './feature/live-ticketing/live-ticketing-plugin-provider';
import LocationPluginProvider from './feature/location/location-plugin-provider';
import MailingBlocklistPluginProvider from './feature/mailing-blocklist/mailing-blocklist-plugin-provider';
import MailingDesignPluginProvider from './feature/mailing-design/mailing-design-plugin-provider';
import MailingTemplatePluginProvider from './feature/mailing-template/mailing-template-plugin-provider';
import MailingPluginProvider from './feature/mailing/mailing-plugin-provider';
import { DefaultMailingProvider } from './feature/mailing/mailing-recipient-extension';
import MyAccountPluginProvider from './feature/my-account/my-account-plugin-provider';
import OrderConfirmationPluginProvider from './feature/order-confirmation/order-confirmation-plugin-provider';
import OrderPluginProvider from './feature/order/order-plugin-provider';
import ParticipationPluginProvider from './feature/participation/participation-plugin-provider';
import PermissionClassGroupPluginProvider from './feature/permission-class-group/permission-class-group-plugin-provider';
import PermissionRolePluginProvider from './feature/permission-role/permission-role-plugin-provider';
import PersonPluginProvider from './feature/person/person-plugin-provider';
import PlacementPluginProvider from './feature/placement/placement/placement-plugin-provider';
import ProductBundlePluginProvider from './feature/product-bundle/product-bundle-plugin-provider';
import ProductPluginProvider from './feature/product/product-plugin-provider';
import AwardWinnerPluginProvider from './feature/relations/award-winner/award-winner-plugin-provider';
import CompanyFormatPluginProvider from './feature/relations/format-company/company-format-plugin-provider';
import FormatCompanyPluginProvider from './feature/relations/format-company/format-company-plugin-provider';
import FormatOccupationPluginProvider from './feature/relations/format-occupation/format-occupation-plugin-provider';
import GroupPluginProvider from './feature/relations/group/group-plugin-provider';
import PersonAccessGroupPluginProvider from './feature/relations/person-access-group/person-access-group-plugin-provider';
import SectionStaffPluginProvider from './feature/relations/section-staff/section-staff-plugin-provider';
import TeamStaffPluginProvider from './feature/relations/team-staff/team-staff-plugin-provider';
import ReportingPluginProvider from './feature/reporting/reporting-plugin-provider';
import RundownPluginProvider from './feature/rundown/rundown-plugin-provider';
import SearchPluginProvider from './feature/search/search-plugin-provider';
import SectionPluginProvider from './feature/section/section-plugin-provider';
import StaffPluginProvider from './feature/staff/staff-plugin-provider';
import StandPluginProvider from './feature/stand/stand-plugin-provider';
import SystemMailingPluginProvider from './feature/system-mailing/system-mailing-plugin-provider';
import TagPluginProvider from './feature/tag/tag-plugin-provider';
import TeamPluginProvider from './feature/team/team-plugin-provider';
import VisaTemplatePluginProvider from './feature/visa-template/visa-template-plugin-provider';
import VisaPluginProvider from './feature/visa/visa-plugin-provider';
import { PluginRegistry } from './util/plugin/use-plugins';

const registry = new PluginRegistry();

export const plugins = [
  AccessGroupPluginProvider,
  AccountPluginProvider,
  AdmissionProvider,
  AwardPluginProvider,
  AwardWinnerPluginProvider,
  CodePluginProvider,
  CompanyPluginProvider,
  CompanyAdminPluginProvider,
  CompanyFormatPluginProvider,
  CountryPluginProvider,
  DefaultMailingProvider,
  EditionPluginProvider,
  EventEvaluationProvider,
  EventsOfPersonProvider,
  EventPluginProvider,
  EventProvider,
  ExportPluginProvider,
  FilmPluginProvider,
  FormatPluginProvider,
  FormatCompanyPluginProvider,
  FormatOccupationPluginProvider,
  GroupTemplatePluginProvider,
  GroupPluginProvider,
  GuestListLandingPageProvider,
  GuestListProvider,
  InvoicePluginProvider,
  KeyPlayerPluginProvider,
  LandingPageDesignPluginProvider,
  LandingPageTemplateModuleProvider,
  LandingPageModuleProvider,
  LandingPagePluginProvider,
  LandingPageTemplatePluginProvider,
  ListPluginProvider,
  LiveTicketingPluginProvider,
  LocationAppointmentProvider,
  LocationPluginProvider,
  MailingBlocklistPluginProvider,
  MailingDesignPluginProvider,
  MailingPluginProvider,
  MailingTemplatePluginProvider,
  MyAccountPluginProvider,
  OrderPluginProvider,
  OrderConfirmationPluginProvider,
  ParticipationPluginProvider,
  PermissionClassGroupPluginProvider,
  PermissionRolePluginProvider,
  PersonAccessGroupPluginProvider,
  PersonAppointmentProvider,
  PersonPluginProvider,
  PlacementPluginProvider,
  ProductBundlePluginProvider,
  ProductPluginProvider,
  ReportingPluginProvider,
  RundownPluginProvider,
  SearchPluginProvider,
  SectionPluginProvider,
  SectionStaffPluginProvider,
  StaffPluginProvider,
  StandPluginProvider,
  SystemMailingPluginProvider,
  TagPluginProvider,
  TeamStaffPluginProvider,
  TeamPluginProvider,
  VisaTemplatePluginProvider,
  VisaPluginProvider,
];

plugins.forEach((plugin) => plugin(registry));

export default registry;
